.horario-item{
    display: grid;
    grid-template-columns: 1fr 2fr;
    gap: 1rem;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 2rem 0;
}

.horario-item-time{
    background-color: #000000;
    width: max-content;
    justify-self: center;
    padding: 0.5rem 1rem;
    width: 80%;
}

.horario-item h1{
    font-family: lemon-regular;
    font-weight: bold;
    font-size: 1.5em;
}


.horario-item p{
    font-family: lemon-light;
    font-size: 1.2em;
}

.horario-item-text{
    display: flex;
    flex-direction: column;
    justify-self: start;
    text-align: left;
    color: black;
}

@media screen and (max-width: 768px) {
    .horario-item{
        grid-template-columns: 1fr;
    }

    .horario-item-text{
        justify-self: center;
        text-align: center;
    }
}