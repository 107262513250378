@import url('https://fonts.googleapis.com/css2?family=Climate+Crisis&display=swap');

@import url('https://fonts.cdnfonts.com/css/cocogoose');

@import url('https://www.dafont.com/cocogoose.font');

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face{
  font-family: 'Climate Crisis';
  src: url('assets/ClimateCrisis-Regular-VariableFont_YEAR.ttf');
}

@font-face{
  font-family: 'Cocogoose-regular';
  src: url('assets/Cocogoose_trial.otf') ;
  
}

@font-face {
  font-family: Lemon-light;
  src: url('./assets/LEMONMILK-Light.otf');
}

@font-face {
  font-family: Lemon-regular;
  src: url('./assets/LEMONMILK-Regular.otf');
}

@font-face {
  font-family: Lemon-bold;
  src: url('./assets/LEMONMILK-Bold.otf');
}

