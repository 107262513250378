.repeated-titles {
    display: flex;
    justify-content: center;
    align-items: center;
    white-space: nowrap;
}

.repeated-title {
    flex: none;
    white-space: nowrap;
    font-family: 'Climate Crisis';
    font-size: 3rem;
    width: max-content;
    justify-self: center;
    -webkit-text-stroke-width: 3px; /* Largura do contorno */
    -webkit-text-stroke-color: white; /* Cor do contorno (branco) */
    color: #FFDE16;
    margin: 0 0.5rem;
}

.central {
    color: black; /* Specifically setting the central title color */ 
    -webkit-text-stroke-width: 0px; /* Largura do contorno */
    -webkit-text-stroke-color: black; /* Cor do contorno (branco) */
    font-family: 'Climate Crisis';
}

/* Responsive adjustments */
@media screen and (max-width: 1024px) {
    .repeated-title {
        font-size: 2.5em;
    }
}

@media screen and (max-width: 768px) {
    .repeated-title {
        font-size: 2em;
    }
}