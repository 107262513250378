.horario{
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");
}
.container{
    width: 90%;
    display: grid;
    grid-template-columns: 1fr 3fr;
}

.dias{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
    border: 3px solid white;
    padding: 2rem 0;
    height: min-content;
    
}

.dia{
    color: white;
}

.selected{
    color: #000000;
    font-family: lemon-regular;
    
}

.dia h1{
    font-size: 7rem;
    line-height: 10rem;
    font-family: 'Climate Crisis';

}

.dia p{
    font-family: 'lemon-regular';
    font-size: 1.5em;

}

.dia-semana h2{
    font-family: 'lemon-regular';
    font-weight: bold;
    font-size: 1em;
}

.horario-flex{
    display: flex;
    flex-direction: column;
    border: 3px solid white;
}

@media screen and (max-width: 768px) {
    .container{
        grid-template-columns: 1fr;
    }

    .dias{
        flex-direction: row;
        justify-content: space-evenly;
        border-bottom: none;
    }

    .dia h1{
        font-family: 'Climate Crisis';
        font-size: 4rem;
        line-height: 5rem;
    }
    
}