.informacao{
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");
}

.informacao-flex{
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    gap: 1rem;
    padding: 5rem 0;
}

.informacao-item{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
}

.informacao-item-titulo{
    font-family: 'lemon-regular';
    font-size: 1.5em;
    color: #000000;
}

@media screen and (max-width: 768px) {
    .informacao{
        gap: 3rem;
    }

    .informacao-flex{
        flex-direction: column;
        gap: 3rem;
        padding: 1rem 0;
    }
}