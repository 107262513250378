.speaker{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    width: 100%;
}
.speaker-imgs{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: end;
    justify-items: center;
    width: 100%;
}

.speaker-imgs img{
    grid-column: 1;
    grid-row: 1;
}


.speaker-img{
    width: 60%;
    height: auto;
}


.speaker-text{
    display: flex;
    flex-direction: column;
    align-items: center;
    color: black;
    gap: 0.4rem;
    
}

.speaker-text h1{
    font-size: 1.5em;
    padding: 4px 8px;
    font-family: bold;
}

.speaker-text p{
    font-family: Regular;
    font-size: 1em;
    
}