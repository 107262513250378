.sobrenos{
    background-color: #f5f5f5;
    background-image: url("../../assets/Shapes/Background.png");
    position: relative;
}

.inscricao{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
}

.button-text, .button-background{
    grid-column: 1;
    grid-row: 1;
    font-family: Bold;
    font-size: 3em;
    color: #000000;
    width: max-content;
}

.button-text{
    z-index: 1;
    background-color: white;
    padding: 1rem 3rem;
    font-family: 'Climate Crisis';
    border: #000000 4px solid;
    border-bottom-width: 6px;
    
}

.porques{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
}

.porque{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    
}

.porque:nth-child(-n+3){
    border-bottom: 4px solid #000000;
    padding: 1rem 3rem 3rem 3rem;
}

.porque:nth-last-child(-n+3){
    padding: 3rem 3rem 1rem 3rem;
}

/*select the 1 2 4 5 of porque class*/
.porque:nth-child(1), .porque:nth-child(2), .porque:nth-child(4), .porque:nth-child(5){
    border-right: 4px solid #000000;
}

.porque-titulo{
    font-family: SemiBold;
    font-size: 1.5em;
    color: #000000;
    padding: 8px;
}

.porque-texto{
    font-family: Regular;
    font-size: 1.2em;
    color: #000000;
    padding: 0 2rem;
}

.paragrafo{
    font-family: 'Cocogoose-regular';    
    font-size: 2em;
    color: black;
    
}

.sobrenos-imagens{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 4rem;
    width: 75%;
    padding: 0 3rem;
}

.sobrenos-box{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
}

.sobrenos-titulo{
    font-family: Bold;
    font-size: 1.5em;
}

.img-boneco{
    width: 50%;
    position: absolute;
    right: 0;
    z-index: 10;
}

.sobre-imagem{
    grid-column: 2;
    grid-row: 1/3;
    justify-self: end;
    align-self: end;
    width: 30%;
    height: auto;
    position: absolute;
    bottom: 0;
    right: 0;
     
}


@media screen and (max-width: 1200px) {

    .sobrenos-imagens {
        display: grid;
        grid-template-columns: (2, 1fr);
        width: 70%;
        padding: 0;
    }

    .sobrenos-imagens img {
        max-width: 90%;
        height: auto;
        margin: 10px 0;
    }


    .paragrafo{
        font-size: 1.2em;
    }
}


@media screen and (max-width: 768px) {
    .button-text{
        font-size: 1rem;
        width: max-content;
    }

    .porques{
        grid-template-columns: 1fr;
    }

    .porque{
        border-bottom: 4px solid black;
    }

    .porque:nth-child(1), .porque:nth-child(2), .porque:nth-child(4), .porque:nth-child(5){
        border-right: none;
    }

    .porque:nth-child(6){
        border-bottom: none;
    }

    .porque:nth-last-child(-n+3), .porque:nth-child(-n+3){
        padding: 3rem 0rem;
    }

    .sobrenos-imagens {
        display: grid;
        grid-template-columns: 1fr;
        width: 100%;
        padding: 0; 
    }

    .sobrenos-imagens img {
        max-width: 90%;
        height: auto;
        margin: 10px 0;
    }

    .paragrafo{
        font-size: 1.2em;
    }

    
}

