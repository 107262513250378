.pergunta{
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    color: black;
    border: 3px solid black;
    border-top: none;
    text-align: left;
    width: 100%;
    height: min-content;
    cursor: pointer;
}

.pergunta:first-child{
    border-top: 3px solid black;
}

.pergunta h3{
    font-size: 1.2em;
    font-family: 'Lemon-regular';
    z-index: 1;
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");
}

.pergunta p{
    font-size: 1em;
    font-family: 'Lemon-light';
    padding: 0 1rem 1rem 1rem;   
}

.pergunta-sempre{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1rem;
}

.arrowClose{
    transform: rotate(180deg);
    width: 5%;
    height: 5%;
}

.arrowOpen{
    width: 5%;
    height: 5%;
}

.gapCloseEnd{
    animation: slideUp 0.5s ease-in-out;
}

.gapClose{
    display: none;
}

.gapOpen{
    display: block;
    animation: slideDown 0.5s ease-in-out;
}

@keyframes slideDown {
    0% {
        transform: translateY(-100%);
    }
    100% {
        transform: translateY(0);
    }
}

@keyframes slideUp {
    0% {
        transform: translateY(0);
    }
    100% {
        transform: translateY(-100%);
    }
}
