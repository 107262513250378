.header{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    padding: 1rem 3rem;
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 100;
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");
}

.header-mobile{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    background: none;
}

.navbar-small{
    display: flex;
    justify-content: flex-end; 
    align-items: center;
    gap: 1rem;
}

.header img{
    height: 4rem;
}

.navbar-item{
    font-size: 1.2em;
    color: #000000;
    padding: 4px 8px;
    font-family: Regular;
    border-bottom: 1px solid transparent;	
}

.active{
    font-size: 1.2em;
    color: #000000;
    transition: color 0.2s ease;
    transition-delay: 0.25s;
    font-family: bold;
    padding: 4px 8px;
    border-bottom: 2px solid #000000;
    transition: border-bottom 0.2s ease, color 0.2s ease;
	transition-delay: 0.25s;
}

.hamburger{
    width: 24px;
    display: flex;
    flex-direction: column;
    gap: 4px;
}

.line{
    width: 100%;
    height: 2px;
    background-color: white;
}

@media screen and (max-width: 768px) {
    .header{
        flex-direction: column;
        align-items: flex-end;
    }
    
    .navbar{
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        gap: 1rem;
        width: 100vw;
        height: 100vh;
    }
}