.home{
    height: 100vh;
    background-color: #FFDE16;
    /* background image */
    background-image: url("../../assets/Shapes/Background.png"); 
    /* use this to make the background image cover the whole screen */
    background-size: cover;
    /* use this to make the background image not repeat */
    background-repeat: no-repeat;
    /* use this to make the background image centered vertically and horizontally */
    background-position: center center;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-template-rows: repeat(2, 1fr);
    align-items: center;
    justify-items: start;
    padding: 10rem 5rem 0 5rem;
    
}

.home-text{
    display: flex;
    flex-wrap: wrap;
    font-family: 'Climate Crisis';
}

.home-title{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    text-align: left;
    width: min-content;
}

.home-title:nth-child(3){
    grid-column: span 2;
    grid-row: 2;
}

.home-title1{
    grid-column: 1;
    grid-row: 1;
    font-size: 3rem;
    width: max-content;
    justify-self: center;
    color: white !important;
}

.home-title2{
    grid-column: 1;
    grid-row: 1;
    font-size: 3.5rem;
    width: max-content;
    justify-self: center;
    color: black !important;
}

.home-title3{
    grid-column: 1;
    grid-row: 1;
    font-size: 3.5rem;
    width: max-content;
    justify-self: center;
    -webkit-text-stroke-width: 3px; /* Largura do contorno */
    -webkit-text-stroke-color: white; /* Cor do contorno (branco) */
    color: #FFDE16 !important;
}

.home-title1{
    z-index: 1;
}

.home-title2{
    position: relative;
    top: 10px;
    left: 10px;
}


.home-img{
    grid-column: 2;
    grid-row: 1/3;
    justify-self: end;
    align-self: end;
    width: 100%;
    height: auto;
}


@media screen and (max-width: 1200px) {

    .home-title1 {
        margin-top: 1rem;
        font-size: 3rem;
        justify-self: start;
    }

    .home-title2{
        font-size: 3rem;
    }

    .home-title3{
        font-size: 3rem;
    }

    .home-img{
        width: 100%;
    }


}

@media screen and (max-width: 1024px) {
    .home-title1, .home-title2, .home-title3{
        font-size: 2rem;
    }
}


@media screen and (max-width: 768px){
    .home{
        padding: 10rem 2rem 0 2rem;
    }

    .home-title1, .home-title2, .home-title3{
        font-size: 2rem;
        top: 6px;
        left: 6px;
    }

    .home-title2{
        position: relative;
        top: 4px;
        left: 4px;
    }

    .home-title3{
        position: relative;
        top: 4px;
        left: 4px;
        -webkit-text-stroke-width: 1.5px; /* Largura do contorno */
    }

    .home-img{
        grid-column: 1;
        grid-row: 2;
        justify-self: center;
        align-self: center;
        width: 50%;
    }

}



