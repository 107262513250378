.titulos{
    display: flex; /* Usamos flexbox para facilitar a centralização */
    align-items: center; /* Centraliza verticalmente */
    justify-content: center; /* Centraliza horizontalmente */
    width: 100%;
    font-family: 'Climate Crisis';
    padding: 0 1rem; /* Adiciona padding para garantir que o texto não toque nas bordas */
    box-sizing: border-box;
}

.titulo{
    grid-column: 2;
    grid-row: 1;
    font-family: Bold;
    font-size: 3em;
    width: max-content;
    justify-self: center;
    font-family: 'Climate Crisis';
    text-align: center; /* Center align the text */
    
}

.segundo{
    position: relative;
    top: 3px;
    left: 2px;
}

.black{
    z-index: 1;
    color: black;
}

.secondhr{
    grid-column: 3;
}


.black{
    color: #000000;
}

.white{
    color: #FFFFFF;
}

@media screen and (max-width: 1200px) {
    .titulo {
        font-size: 2.5em;
        width: 100%;
        padding: 0 1rem; 
        box-sizing: border-box;
    }
}

@media screen and (max-width: 1024px) {
    .titulo {
        font-size: 2em;
        width: 100%;
        padding: 0 1rem;
        box-sizing: border-box;
    }
    
}

@media screen and (max-width: 768px) {
    .titulo{
        display: block;
        font-family: 'Climate Crisis', sans-serif;
        font-size: 2em;
        width: 100%; 
        text-align: center;
        word-wrap: break-word; 
        padding: 0 1rem; 
        box-sizing: border-box;
    }

}



