.perguntas{
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");

}

.perguntas-grid{
    display: grid;
    grid-template-columns: 1fr;
    width: 80%;
}