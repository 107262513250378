.oradores{
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");
}

.oradores-grid{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    width: 90%;
    gap: 4rem 0;
}

@media screen and (max-width: 768px) {
    .oradores-grid{
        grid-template-columns: 1fr;
    }
}