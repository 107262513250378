.parceiros{
    background-color: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");
}

.parceiros-grid{
    display: flex;
    flex-direction: column;
}

.parceiro{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 0.5rem;
    color: black;
    font-family: Bold;
    font-size: 1.2em;
    text-align: center;
}

.torna-te{
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    align-items: center;
    justify-items: center;
    
}

.button-text2{
    z-index: 1;
    background-color: white;
    padding: 1rem 3rem;
    font-family: lemon-bold;
    font-size: 1.2em;
    border: #000000 4px solid;
    border-bottom-width: 6px;
}

.UC{
    width: 100%;
}



