.bilhetes{
    background: #FFDE16;
    background-image: url("../../assets/Shapes/Background.png");

}

.bilhetes-grid{
    display: grid;
    grid-template-columns: 1fr;
    gap: 2rem;
    width: 80%;
    color: black;
    font-family: 'lemon-regular';
}

.bilhete{
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
}

.bilhete p{
    font-size: 2em;
}

@media screen and (max-width: 768px) {
    .bilhetes-grid{
        grid-template-columns: 1fr;
        gap: 3rem;
    }

    .bilhete{
        gap: 1rem;
    }

    .bilhete p{
        font-size: 1.5em;
    }
}